<template>
  <div id="app">
    <publicHead></publicHead>
    <router-view class="appCont" v-if="isRouterAlive" :key="$route.fullPath" />
    <publicFoot v-show="$route.meta.footShow" ref="usernameInput"></publicFoot>
    <img src="@/assets/img/shang.png" class="toUp" @click="toUp" alt="" v-if="istoUp == true && isShow == true">
    <el-tooltip class="whatsApp" effect="dark" placement="left" popper-class="sub-poper-class" :content="whatsapp">
      <div class="el-whatsApp_one">
        <img src="./assets/image/whatsapp.png" alt="">
        WhatsApp
      </div>
    </el-tooltip>
    <Information></Information>
  </div>
</template>
<script>
import publicHead from "@/components/publicHead.vue";
import publicFoot from "@/components/publicFoot.vue";
import Information from "@/components/Information.vue";

export default {
  components: {
    publicHead,
    publicFoot,
    Information,
  },
  provide() {
    return {
      reload: this.reload,
    };
  },
  mounted() {
    console.log(process.env.NODE_ENV);
    window.addEventListener("scroll", this.handleScrollbox, true);
    // this.handleScrollbox();

    this.handleResize()
    window.addEventListener('resize', this.handleResize);
    this.getInfo()
  },
  data() {
    return {
      isRouterAlive: true,
      istoUp: false,

      isShow: false,

      isKefu: false,
      whatsapp:'',

    };
  },
  methods: {
    getInfo() {
      this.$request({
        url: "/Index/index",
      }).then((res) => {
        this.whatsapp = res.data.whatsapp
      });
    },
    reload() {
      console.log("刷新当前页面");
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
        this.$refs.usernameInput.getInfo();
      });
      
    },

    handleScrollbox() {
      this.currentScroll = window.pageYOffset; //表示当前滚动的位置
      if (this.currentScroll > 200) {
        this.istoUp = true
      } else {
        this.istoUp = false
      }

      // if (this.currentScroll >= 0) {
      //   this.$(".head").addClass(
      //     "animate__animated animate__fadeInTop"
      //   );
      //   // this.$(".listItemRight").addClass(
      //   //   "animate__animated animate__fadeInRight"
      //   // );
      // } else {
      //   this.$(".head").removeClass(
      //     "animate__animated animate__fadeInTop"
      //   );
      //   this.$(".listItemRight").removeClass(
      //     "animate__animated animate__fadeInRight"
      //   );
      // }
    },

    /**
     * 监听页面宽度变化
     * 刘嘉鑫
     * 2024-7-20
     */
    handleResize() {
      // this.screenWidth = window.innerWidth;
      if (window.innerWidth > 1024) {
        this.isShow = true
      } else {
        this.isShow = false
      }
    },

    /**
     * 到顶部
     * 刘嘉鑫
     * 2024-7-19
     */
    toUp() {
      console.log(window)
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // 平滑滚动效果
      })
    },
  },
};
</script>
<style>
/*每个页面公共css */
@import "./assets/css/common.css";


.toUp {
  position: fixed;
  bottom: 150px;
  right: 22px;
  height: 40px;
  width: 40px;
  cursor: pointer;
}

.whatsApp {
  position: fixed;
  top: 37%;
  right: 0;
  width: auto;
  min-width: 64px;
  z-index: 999;
}

.el-whatsApp_one {
  background-color: #BA0003;
  font-weight: bold;
  font-size: 11px;
  color: #ffffff;
  text-align: center;
  padding: 8px 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border: 1px solid #eee;
}
.el-whatsApp_one img{
  width: 23px;
  height: 23px;
  border-radius: 50%;
  padding: 0;
  margin-bottom: 10px;
  border: 0;
}
.sub-poper-class.el-tooltip__popper {
  background: #BA0003 !important;
  /* box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15); */
  border: none;
}

/* // 三角形颜色 */
.el-tooltip__popper[x-placement^="left"] .popper__arrow:after,
.el-tooltip__popper[x-placement^="left"] .popper__arrow {
  border-left-color: #BA0003 !important;
  /* 替换为你想要的颜色 */
}
</style>

<template>
  <!--  v-if="!!info" -->
  <div class="footer">
    <div class="footCont screenWidth">
      <div class="footContOne">
        <div class="QRcode">
          <img :src="info.gongzhong" alt="">
          <div>Contact us</div>
        </div>
        <div class="footContOne_one footContOne_two">
          <div class="footContOne_one_title">JIAHONG</div>
          <div class="footContOne_one_2"></div>
          <div :class="listIndex == index ? 'footContOne_one_3 footContOne_one_4' : 'footContOne_one_3'"
            v-for="(item, index) in listOne" @click="jumpPages(index, item)"> {{ item.title }} </div>
        </div>
        <div class="footContOne_one">
          <div class="footContOne_one_title">Product center</div>
          <div class="footContOne_one_2"></div>
          <div class="footContOne_one_3 ceshi" v-for="(item, index) in info.goods_list" @click="toproductDetails(item.id)"> {{
            item.name }} </div>
        </div>
        <div class="footContOne_one footContOne_three">
          <div class="footContOne_one_title">Contact information</div>
          <div class="footContOne_one_2"></div>
          <div class="footContOne_one_3">TEL:{{ info.tel }}</div>
          <div class="footContOne_one_3 footContOne_one_5">Address：{{ info.zongaddress }}</div>
        </div>
      </div>
      <div class="footContTwo">
        <div class="footContTwo_1">Copyright © 2022 All Rights Reserved　All rights reserved· JIAHO</div>
        <div class="footContTwo_2">
          <div class="footContTwo_2_1"></div>
          <div class="footContTwo_2_2"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/**
 * Created by www.xingnuocn.com
 * @title：公共底部
 *
 * @author: 刘嘉鑫
 * @version  V1.0
 * @datecreate: 2022-03-14 10:57
 */
export default {
  data() {
    return {
      listOne: [{
        title: "Home",
        path: "/",
      }, {
        title: "About Us",
        path: "/aboutUs",
      }, {
        title: "Products",
        path: "",
        path: "/product",
      }, {
        title: "News",
        path: "/news",
      }, {
        title: "Contact Us",
        path: "/contactUs",
      }],
      listIndex: 0,
      info: "",
    };
  },
  mounted() {
    this.getInfo()
  },
  watch: {
    // 利用watch方法检测路由变化：
    $route: function (to, from) {
      this.listIndex = to.meta.index;
    },
  },
  methods: {
    // 关于我们。底部信息
    getInfo() {
      this.$request({
        url: "/Index/bottom",
      }).then((res) => {
        console.log("关于我们", res);
        this.info = res.data;
      });
    },

    /**
     * 关于我们跳转
     * 刘嘉鑫
     * 2022-8-24
     */
    jumpPages(index, item) {
      this.listIndex = index;
      console.log(item.path)
      this.$router.push({
        path: item.path,
      });
    },
     /**
     * 去产品详情
     * 刘嘉鑫
     * 2022-8-24
     */
    toproductDetails(id){
      this.$router.push({
        path: '/productDetails',
        query:{id:id}
      });
    }
  },
};
</script>
<style lang='less' scoped>
@media (max-width: 768px) {
  .QRcode {
    img {
      width: 322px !important;
      height: 322px !important;
    }

    div {
      font-size: 48px !important;
    }
  }

  .footContOne_one {
    .footContOne_one_title {
      font-size: 48px !important;
      margin-bottom: 40px !important;
    }

    .footContOne_one_2 {
      width: 58px !important;
      height: 10px !important;
    }

    .footContOne_one_3 {
      font-size: 42px !important;
      margin-bottom: 30px !important;
    }

    .footContOne_one_5 {
      line-height: 56px !important;
    }
  }

  .footContOne_three {
    width: 26% !important;
  }

  .footContTwo {
    padding: 0 30px !important;
  }

  .footContTwo_1 {
    font-size: 42px !important;
  }

  .footContTwo_2_1 {
    width: 60px !important;
    height: 12px !important;
  }

  .footContTwo_2_2 {
    height: 2px !important;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {

  .QRcode {
    img {
      width: 222px !important;
      height: 222px !important;
    }

    div {
      font-size: 40px !important;
    }
  }

  .footContOne_one {
    .footContOne_one_title {
      font-size: 40px !important;
    }

    .footContOne_one_2 {
      width: 42px !important;
      height: 8px !important;
    }

    .footContOne_one_3 {
      font-size: 38px !important;
    }

    .footContOne_one_5 {
      line-height: 50px !important;
    }
  }

  .footContOne_three {
    width: 26% !important;
  }

  .footContTwo_1 {
    font-size: 38px !important;
  }

  .footContTwo_2_1 {
    width: 60px !important;
    height: 8px !important;
  }

  .footContTwo_2_2 {
    height: 2px !important;
  }
}

.footer {
  width: 100%;
  background: #F6F6F6;

  .footCont {
    padding: 100px 0 60px;

    .footContOne {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 90px;
      padding-right: 58px;

      .QRcode {

        img {
          width: 122px;
          height: 122px;
          margin-bottom: 20px;
        }

        div {
          font-weight: bold;
          font-size: 14px;
          color: #222222;
          text-align: center;
        }
      }

      .footContOne_one {
        margin-left: 80px;

        .footContOne_one_title {
          font-weight: bold;
          font-size: 24px;
          color: #222222;
          margin-bottom: 20px;
        }

        .footContOne_one_2 {
          width: 32px;
          height: 4px;
          background: #BA0003;
          margin-bottom: 34px;
        }

        .footContOne_one_3 {
          font-weight: 400;
          font-size: 14px;
          color: #222222;
          margin-bottom: 20px;
          cursor: pointer;
        }

        .footContOne_one_4 {
          color: #BA0003 !important;
        }

        .footContOne_one_5 {
          vertical-align: top;
          line-height: 30px;
        }
      }

      .footContOne_two {
        margin-left: auto;
      }

      .footContOne_three {
        width: 265px;
      }
    }

    .footContTwo {
      .footContTwo_1 {
        font-weight: 400;
        font-size: 14px;
        color: #999999;
        margin-bottom: 18px;
      }

      .footContTwo_2 {
        display: flex;
        align-items: center;

        .footContTwo_2_1 {
          width: 22px;
          height: 5px;
          background: #BA0003;
        }

        .footContTwo_2_2 {
          flex: 1;
          height: 1px;
          background: #BA0003;
          opacity: 0.4;
        }
      }

    }
  }
}
</style>
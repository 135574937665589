import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'homePage',
    component: () => import('../views/homePage.vue'),
    meta: {
      title: "",
      index: 0,
      footShow: true,
      headShow: true
    }
  },
  {
    path: '/aboutUs',
    name: 'aboutUs',
    component: () => import('../views/aboutUs.vue'),
    meta: {
      title: "",
      index: 1,
      footShow: true,
      headShow: true
    }
  },
  {
    path: '/product',
    name: 'product',
    component: () => import('../views/product.vue'),
    meta: {
      title: "product",
      index: 2,
      footShow: true,
      headShow: true
    }
  },
  {
    path: '/productDetails',
    name: 'productDetails',
    component: () => import('../views/productDetails.vue'),
    meta: {
      title: "productDetails",
      index: 2,
      footShow: true,
      headShow: true
    }
  },
  {
    path: '/news',
    name: 'news',
    component: () => import('../views/news.vue'),
    meta: {
      title: "news",
      index: 3,
      footShow: true,
      headShow: true
    }
  },
  {
    path: '/newsDetail',
    name: 'newsDetail',
    component: () => import('../views/newsDetail.vue'),
    meta: {
      title: "newsDetail",
      index: 3,
      footShow: true,
      headShow: true
    }
  },
  {
    path: '/contactUs',
    component: () => import('../views/contactUs'),
    meta: {
      title: '联系我们',
      index: 4,
      footShow: true,
      headShow: true
    }
  },
  {
    path: '/detail',
    component: () => import('../views/detail'),
    meta: {
      title: '',
      index: 0,
      footShow: true,
      headShow: true
    }
  },
]

const router = new VueRouter({
  mode:'hash',
  base: process.env.BASE_URL,
  routes,
  //页面跳转显示在顶部   
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

// 全局前置守卫：任何页面的访问都要经过这里
// to：要去哪里的路由信息
// from：从哪里来的路由信息
// next：通行的标志
// router.beforeEach((to, from, next) => {
//   document.title = '嘉鸿';
//   next()
// })


// 解决重复点击相同路由报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router

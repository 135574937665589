import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 封装axios
import axios from 'axios'
Vue.prototype.$axios = axios
import qs from 'qs'
Vue.prototype.$qs = qs

// npm install amfe-flexible --save
// npm install postcss-pxtorem --save
// https://blog.csdn.net/weixin_45811256/article/details/123556376
import 'amfe-flexible'
// import '@/utils/rem.js'

import ElementUI from 'element-ui'
Vue.use(ElementUI)
import 'element-ui/lib/theme-chalk/index.css'

//导入全局请求
import request from '@/utils/request.js';
Vue.prototype.BASE_URL = request.BASE_URL
Vue.prototype.$request = request.request;
import util from '@/utils/utils.js';
Vue.prototype.$util = util

//iview


// 滚动
import scroll from "vue-seamless-scroll"
Vue.use(scroll)

// // 轮播图
// import VueAwesomeSwiper from 'vue-awesome-swiper'
// import 'swiper/dist/css/swiper.css'
// Vue.use(VueAwesomeSwiper)

// // 动画库
// import animated from 'animate.css'
// Vue.use(animated)

// jquery
import $ from 'jquery'
Vue.prototype.$ = $

import dragscroll from '@/utils/directives.js'; // 引入鼠标拖拽滚动图片指令文件
// 注册鼠标拖拽滚动图片指令
Vue.directive('dragscroll', dragscroll);

//自定义滚动条
import vuescroll from 'vuescroll';

// 配置参数
Vue.use(vuescroll, {
  ops: {
    vuescroll: {
      mode: "native",
      detectResize: true,  //内容是否根据页面调整
    },  
    bar: {
      showDelay: 11500,
      onlyShowBarOnScroll: true, //是否只有滚动的时候才显示滚动条
      keepShow: false,
      background: '#BA0003',
      opacity: 1,
      hoverStyle: false,
      specifyBorderRadius: false,
      minSize: false,
      size: '4px',
      disable: false,
      overflowX: 'hidden',
    },
    scrollPanel: {
      speed: 800,       //多长时间内完成一次滚动。 数值越小滚动的速度越快。
      easing: 'easeInQuad', //默认动画
    }
  },
  name: 'vueScroll' // 在这里自定义组件名字，默认是vueScroll
});
// 定义全局组件
Vue.component('vueScroll', vuescroll);

import currentLocation from "@/components/currentLocation.vue";
Vue.component('currentLocation', currentLocation);

//高德地图
import VueAMap from 'vue-amap';
Vue.use(VueAMap);
VueAMap.initAMapApiLoader({
  key: 'b5ecd12d9683f0808755512d1cbe27cb',
  plugin: [],
  // 默认高德 sdk 版本为 1.4.4
  v: '1.4.4',
  uiVersion: '1.0.11'
});
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

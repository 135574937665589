<template>
  <div class="position ">
    <div class="positionTit">
      <img src="@/assets/img/home.png" class="home" alt="">
      <div v-for="(item, index) in sibItem" :key="index" @click="item.path == '' ? reload() : Jump(item)"
        class="positionTit_1">
        <span v-if="index > 0">&nbsp; / &nbsp;</span>{{ item.title }}
      </div>
    </div>
  </div>
</template>
<script>
/**
 * Created by www.xingnuocn.com
 * @title：当前位置
 *
 * @author: 刘嘉鑫
 * @version  V1.0
 * @datecreate: 2022-04-02 15:00
 */
export default {
  inject: ["reload"], // 刷新页面
  props: {
    sibItem: Array, //这样可以指定传入的类型，如果类型不对，会警告
  },
  data() {
    return {};
  },
  mounted() { },
  methods: {
    // 返回
    Jump(item) {
      if (item.path != "") {
        this.$router.push({
          path: item.path,
        });
      }
    },
  },
};
</script>
<style lang='less' scoped>
// 手机
@media (max-width: 768px) {
  .position{
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .home {
    width: 50px !important;
    height: 50px !important;
    margin-right: 40px !important;
  }

  .positionTit_1 {
    font-size: 80px !important;
  }
}

// 平板
@media (min-width: 768px) and (max-width: 1024px) {
  .position{
    margin-top: 0px;
  }
  .home {
    width: 50px !important;
    height: 50px !important;
    margin-right: 40px !important;
  }

  .positionTit_1 {
    font-size: 30px !important;
  }
}

.position {
  text-align: left;
  padding: 20px 0;
  // margin-bottom: 60px;

  .positionTit {
    display: flex;
    align-items: center;

    .home {
      width: 20px;
      height: 17px;
      margin-right: 10px;
    }

    .positionTit_1:hover{
      color:#BA0003;
    }

    .positionTit_1 {
      font-size: 15px;
      font-weight: 400;
      color: #545454;
      cursor: pointer;
    }
  }
}
</style>
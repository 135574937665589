<template>
  <div class="headBoxOne">
    <div class="head animate__animated animate__fadeInDown">
      <div class="headNav screenWidth headBoxOne">
        <img @click="toHome" src="@/assets/img/jiahong.png" class="headNav_1 handshape" alt="">
        <div class="headNav_2" v-if="isHeng == false">
          <!-- <div v-for="(item, index) in navOneList" :key="index" :class="listIndex == index
          ? 'listItem_active listItem'
          : 'listItem'
          " @click="navChange(index, item)"> {{ item.title }} </div> -->
          <el-menu :default-active="defaultIndex" active-text-color="#222" background-color="#ffffff"
            text-color="#222222" class="el-menu-demo" mode="horizontal" @select="handleSelect">
            <el-menu-item :index="item.index" v-for="(item, index) in navOneList" :key="index"
              v-if="item.index == 0 || item.index == 1">
              <span slot="title">{{ item.title }}</span>
            </el-menu-item>
            <el-submenu :index="item.index" v-for="(item, index) in navOneList" :key="index" v-if="item.index == 2">
              <template slot="title">
                <div class="zhezhao" @click="toproduct('')"></div>
                <span>{{ item.title }}</span>
              </template>
              <el-menu-item-group>
                <el-menu-item @click="toproduct(item2)" index="2" v-for="(item2, index2) in productList"
                  :key="index2">{{
                    item2.name
                  }}</el-menu-item>
              </el-menu-item-group>
            </el-submenu>
            <el-menu-item :index="item.index" v-for="(item, index) in navOneList" :key="index"
              v-if="item.index == 3 || item.index == 4">
              <span slot="title">{{ item.title }}</span>
            </el-menu-item>
          </el-menu>
        </div>
        <img src="@/assets/img/sanheng.png" class="sanheng" alt="" v-if="isHeng == true" @click="drawer = true">
      </div>
    </div>
    <el-drawer :visible.sync="drawer" :before-close="handleClose" size="80%" :with-header="false">
      <div class="phOuter">
        <div class="phOuterOne">
          <img src="@/assets/img/logo.png" alt="" class="phOOImg">
        </div>
        <el-menu :default-active="defaultIndex" class="el-menu-vertical-demo" active-text-color="#222"
          background-color="#ffffff" text-color="#222222" @select="handleSelect">

          <el-menu-item :index="item.index" v-for="(item, index) in navOneList" :key="index"
            v-if="item.index == 0 || item.index == 1">
            <span slot="title">{{ item.title }}</span>
          </el-menu-item>


          <el-submenu :index="item.index" v-for="(item, index) in navOneList" :key="index"
            v-if="item.index == 2 || item.index == 3">
            <template slot="title">
              <span>{{ item.title }}</span>
            </template>
            <el-menu-item-group v-if="item.index == 2">
              <el-menu-item @click="toproduct(item2)" index="2" v-for="(item2, index2) in productListYd"
                :key="index2">{{
                  item2.name
                }}</el-menu-item>
            </el-menu-item-group>
            <el-menu-item-group v-if="item.index == 3">
              <el-menu-item @click="tonews(item2)" index="2" v-for="(item2, index2) in newtablist"
                :key="index2">{{
                  item2.name
                }}</el-menu-item>
            </el-menu-item-group>
          </el-submenu>


          <el-menu-item :index="item.index" v-for="(item, index) in navOneList" :key="index" v-if="item.index == 4">
            <span slot="title">{{ item.title }}</span>
          </el-menu-item>

        </el-menu>
      </div>
    </el-drawer>
  </div>
</template>
<script>
/**
 * Created by www.xingnuocn.com
 * @title：公用头部
 *
 * @author: 刘嘉鑫
 * @version  V1.0
 * @datecreate: 2022-03-14 10:16
 */
export default {
  inject: ["reload"], // 刷新页面
  data() {
    return {
      navOneList: [
        {
          title: "Home",
          path: "/",
          index: "0",
        },
        {
          title: "About Us",
          path: "/aboutUs",
          index: "1",
        },
        {
          title: "Products",
          path: "/product",
          index: "2",
        },
        {
          title: "News",
          path: "/news",
          index: "3",
        },
        {
          title: "Contact Us",
          path: "/contactUs",
          index: "4",
        }
      ],
      listIndex: 0,
      defaultIndex: "0",

      info: "",

      userInfo: "",
      token: "",

      isHeng: false,
      drawer: false,

      productList: [],
      productListYd: [],//移动端

      newtablist: [],//新闻移动端
    };
  },
  mounted() {
    this.handleResize()
    this.getnewlist()
    window.addEventListener('resize', this.handleResize);
    window.addEventListener("scroll", this.handleScrollbox, true);

    this.getInfo()
    
  },
  watch: {
    // 利用watch方法检测路由变化：
    $route: function (to, from) {
      console.log(to)
      this.listIndex = to.meta.index;
      this.defaultIndex = String(to.meta.index)
    },
  },
  methods: {
    getnewlist() {
      this.$request({
        url: "/Index/newsType",
      }).then((res) => {
        this.newtablist = res.data.news_type
      });
    },
    toHome() {
      this.$router.push({
        path: '/',
      });
    },
    tonews(item){
      this.listIndex = 3;
      this.defaultIndex = 3
      this.$router.push({
        path: '/news',
        query: { id: item.id ,name:item.name}
      });
      this.drawer = false
    },
    toproduct(item) {
      console.log(item, '移动点击了');
      this.listIndex = 2;
      this.defaultIndex = 2
      this.$router.push({
        path: '/product',
        query: { id: item.id ,name:item.name}
      });
      this.drawer = false
    },
    // 关于我们。底部信息
    getInfo() {
      this.$request({
        url: "/Index/goodsCateOne",
      }).then((res) => {

        // console.log("关于我们", res);
        this.productList = res.data.cate_list;
        this.productListYd = res.data.cate_list
        this.productListYd.unshift({ name: 'All', id: '', pid: '' })
      });
    },

    // nav切换  刘嘉鑫  2022-8-10
    handleSelect(key, keyPath) {
      // if(key == 2)return
      console.log(key, keyPath, '数据');
      this.listIndex = key;
      this.defaultIndex = key
      this.$router.push({
        path: this.navOneList[key].path,
      });
      this.drawer = false
    },
    navChange(index, item) {
      this.listIndex = index;
      this.defaultIndex = this.navOneList[index].index
      this.$router.push({
        path: this.navOneList[index].path,
      });
    },

    handleScrollbox() {
      this.currentScroll = window.pageYOffset; //表示当前滚动的位置
      // if (this.currentScroll <= 10) {
      //   this.$(".headNav").removeClass("fixed");
      // } else {
      //   this.$(".headNav").addClass("fixed");
      // }
    },

    /**
     * 监听页面宽度变化
     * 刘嘉鑫
     * 2024-7-20
     */
    handleResize() {
      // this.screenWidth = window.innerWidth;
      if (window.innerWidth < 1024) {
        this.isHeng = true
      } else {
        this.isHeng = false
      }
    },

    handleClose() {
      this.drawer = false
    },
  },
};
</script>
<style>
.zhezhao {
  background: rgba(0, 0, 0, 0);
  width: 100%;
  height: 100px;
  position: absolute;
}

.el-menu-item:hover {
  color: #BA0003 !important;
}

.el-menu-item.is-active:hover {
  color: #BA0003 !important;
}

.el-popover {
  padding: 0 !important;
}

/* // 更改鼠标划过背景色字体色及borer显示 */
.el-menu-item:hover,
.el-submenu__title:hover,
.el-menu.el-menu--horizontal {
  background: transparent !important;
  border-bottom: none !important;
  -webkit-tap-highlight-color: transparent !important
}

.el-menu-demo {
  width: 100% !important;
}


.el-menu--horizontal>.el-menu-item.is-active,
.el-menu--horizontal>.el-submenu.is-active {
  color: #FFFFFF !important;
  background-color: #BA0003 !important;
  border: none !important;
}

.el-menu--horizontal>.el-submenu.is-active .el-submenu__title {
  color: #FFFFFF !important;
  border: none !important;
}

.el-menu--horizontal>.el-submenu.is-active .el-submenu__title i {
  color: #ffffff !important;
}

.el-menu--horizontal>.el-menu-item,
.el-menu--horizontal>.el-submenu {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  /* width: 16.8%; */
  height: inherit !important;
  line-height: inherit !important;
  padding: 20px 1em;
  background: #FFFFFF;
  font-weight: 400;
  font-size: 14px;
  color: #222222;
  font-weight: bold;
  overflow: hidden;
  margin-left: 17px !important;
  border-bottom-color: transparent !important;
  border: none !important;
}

.el-menu--horizontal>.el-submenu .el-submenu__title {
  border: none !important;
  height: inherit !important;
  line-height: inherit !important;
  width: 100% !important;
  padding: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent !important;
}
</style>
<style lang='less' scoped>
// 手机
@media (max-width: 768px) {
  .headBoxOne {
    height: 240px !important;
  }

  .headNav_1 {
    width: 260px !important;
    height: 110px !important;
  }

  .sanheng {
    width: 86px !important;
    height: 80px !important;
    margin-left: auto !important;
    padding: 0 60px !important;
  }
}

// 平板
@media (min-width: 768px) and (max-width: 1024px) {
  .headBoxOne {
    height: 150px !important;
  }

  .headNav_1 {
    width: 170px !important;
    height: 75px !important;
  }

  .sanheng {
    width: 50px !important;
    height: 50px !important;
    margin-left: auto !important;
    padding: 0 0 0 60px !important;
  }
}


.headBoxOne {
  height: 110px;
}

.head {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background: #ffffff;
}

.headNav {
  display: flex;
  align-items: center;
}

.headNav_1 {
  width: 135px;
  height: 60px;
}

.headNav_2 {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;
  // width:90%;

  .listItem {
    cursor: pointer;
    text-align: center;
    width: 16.8%;
    padding: 22px 0;
    background: #FFFFFF;
    font-weight: 400;
    font-size: 16px;
    color: #222222;
    overflow: hidden;
    margin-left: 37px;
  }

  .listItem_active {
    color: #FFFFFF !important;
    background: #BA0003 !important;
    border-radius: 4px;
  }
}


.phOuter {
  display: flex;
  flex-direction: column;
  height: 100vh;


  .phOuterOne {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 100px 40px 40px;

    .phOOImg {
      width: 163px;
      height: 66px;
    }
  }

  .phOuterTwo {
    display: flex;
    align-items: center;
    padding-bottom: 60px;
    border-bottom: 2px solid #EEEEEE;
    margin: 60px 70px 0;

    .phTwoName {
      font-size: 60px;
      font-weight: bold;
      color: #222222;
      margin-right: 20px;
    }

    .phTwoNameEn {
      font-size: 24px;
      font-weight: 400;
      color: #AAAAAA;
    }
  }

  .active div {
    color: #BA0003 !important;
  }
}
</style>
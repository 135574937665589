<template>
    <div>
        <div class="Information">
            <div class="infobox" v-if="!kfshow">
                <div class="infobox_hearder">
                    Contact us
                </div>
                <div class="form_box">
                    <div class="top">
                        <div class="xn_box">
                            <img src="../assets/image/xn.png" alt="">
                        </div>
                        <div class="title">Email transcript to :</div>
                    </div>
                    <div class="centent_box">
                        <input v-model="name" class="input_box" type="text" placeholder="Name">
                        <input @input="handleInput(1)" v-model="email" @blur="checkInput('email')"
                            :class="{ 'input_box': true, 'active': inputshowone }" type="text" placeholder="*Email">
                        <div v-if="inputshowone" class="Tips">This field is required</div>
                        <input @input="handleInput(2)" v-model="tel" @blur="checkInput('tel')"
                            :class="{ 'input_box': true, 'active': inputshowtow }" type="text"
                            placeholder="*Tel/Whatsapp">
                        <div v-if="inputshowtow" class="Tips">This field is required</div>
                        <textarea @input="handleInput(3)" v-model="Description" @blur="checkInput('Description')"
                            :class="{ 'input_box': true, 'active': inputshowthree }" placeholder="*Message"></textarea>
                        <div v-if="inputshowthree" class="Tips">This field is required</div>
                    </div>
                </div>
                <button class="tj" @click="tijiao">Send</button>

            </div>
            <div class="Customer_box" @click="Popup">
                <svg v-if="kfshow" class="tawk-min-chat-icon" viewBox="0 0 800 800">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M400 26.2c-193.3 0-350 156.7-350 350 0 136.2 77.9 254.3 191.5 312.1 15.4 8.1 31.4 15.1 48.1 20.8l-16.5 63.5c-2 7.8 5.4 14.7 13 12.1l229.8-77.6c14.6-5.3 28.8-11.6 42.4-18.7C672 630.6 750 512.5 750 376.2c0-193.3-156.7-350-350-350zm211.1 510.7c-10.8 26.5-41.9 77.2-121.5 77.2-79.9 0-110.9-51-121.6-77.4-2.8-6.8 5-13.4 13.8-11.8 76.2 13.7 147.7 13 215.3.3 8.9-1.8 16.8 4.8 14 11.7z">
                    </path>
                </svg>
                <svg v-if="!kfshow" class="tawk-min-chat-icon cha" viewBox="0 0 800 800">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M466.24042,400.4053l272.927-275.99463c6.94586-8.76452,11.13092-20.00501,11.13092-32.20959 s-4.18506-23.42317-11.21857-32.29724l0.08765,0.10955c-8.76453-6.94588-20.005-11.13094-32.20959-11.13094 c-12.20453,0-23.42316,4.18505-32.29724,11.21858l0.10956-0.08765L401.84311,336.008L125.84851,60.0134 c-8.76452-6.94588-20.00501-11.13094-32.2096-11.13094s-23.42316,4.18506-32.29724,11.21858l0.10955-0.08765 C54.50535,68.77792,50.32029,80.01842,50.32029,92.223s4.18505,23.42317,11.21858,32.29724l-0.08764-0.10956l275.9946,275.99463 L61.45122,673.33234c-6.94588,8.76453-11.13094,20.005-11.13094,32.20959s4.18506,23.42316,11.21858,32.29724l-0.08765-0.1095 c8.19483,7.64703,19.2162,12.33606,31.33314,12.33606c0.83263,0,1.68717-0.02191,2.49789-0.06573h-0.10957 c0.54779,0.02191,1.20512,0.04382,1.86246,0.04382c11.32813,0,21.5388-4.71094,28.79144-12.29224l0.0219-0.02191 l275.99463-272.92703l272.92703,272.92703c7.2746,7.58136,17.48523,12.31415,28.81335,12.31415 c0.65735,0,1.29279-0.02191,1.95013-0.04382h-0.08765c0.72308,0.04382,1.55573,0.06573,2.38831,0.06573 c12.11694,0,23.16022-4.68903,31.37695-12.35797l-0.02185,0.02191c6.94586-8.76447,11.13092-20.005,11.13092-32.20959 c0-12.20453-4.18506-23.42316-11.21857-32.29724l0.08765,0.10956L466.24042,400.4053z">
                    </path>
                </svg>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            isRouterAlive: true,
            kfshow: true,
            inputshowone: false,
            inputshowtow: false,
            inputshowthree: false,

            name: '',
            email: '',
            tel: '',
            Description: '',
        }
    },
    methods: {
        Popup() {
            this.kfshow = !this.kfshow
        },
        handleInput(index) {
            console.log(index);
            if (index == 1) {
                this.inputshowone = false
            } else if (index == 2) {
                this.inputshowtow = false
            } else if (index == 3) {
                this.inputshowthree = false
            }
        },
        checkInput(field) {
            console.log(field);
            if (field === 'email' && this.email == '') {
                this.inputshowone = true // 显示提示信息如果email为空
            } else if (field === 'tel' && this.tel == "") {
                this.inputshowtow = true; // 显示提示信息如果tel为空
            } else if (field === 'Description' && this.Description == '') {
                this.inputshowthree = true
            }
        },
        /**
            * 提交联系我们
            * 刘义博
            * 2024-7-20
            */
        tijiao() {
            console.log('123');
            if (this.email == "") {
                this.$message({
                    message: 'Please fill in yo r email address',
                    type: 'warning',
                    offset: 100
                });
                return
            } else if (this.tel == "") {
                this.$message({
                    message: 'Please fill in your phone number or Whatsapp account',
                    type: 'warning',
                    offset: 100
                });
                return
            } else if (this.Description == "") {
                this.$message({
                    message: 'Please leave us a message to understand your needs',
                    type: 'warning',
                    offset: 100
                });
                return
            }
            this.$request({
                url: "/Index/feedBack",
                data: {
                    name: this.name,
                    mobile: this.tel,
                    email: this.email,
                    message: this.Description,
                }
            }).then((res) => {
                console.log("提交成功", res);
                if (res.code == 1) {
                    this.$message({
                        message: res.msg,
                        type: 'success',
                        offset: 100
                    });
                    this.name = ""
                    this.email = ""
                    this.tel = ""
                    this.Description = ""
                }
            }).catch(err => {
                console.log(err, '123');
                this.$message({
                    message: err.msg,
                    type: 'warning',
                    offset: 100
                });
            })
        },
    },
    mounted() { },
    watch: {},
    computed: {},
    filters: {},
    components: {}
}
</script>
<style scoped lang="less">
/* 表单样式 */
@media (min-width: 1px) and (max-width: 767px) {
    .tawk-min-chat-icon {
        width: 110px !important;
        height: 110px !important;
    }

    .cha {
        width: 70px !important;
        height: 70px !important;
    }

    .Information {
        bottom: 3vh !important;
        right: 1vh !important;

        .Customer_box {
            width: 230px !important;
            height: 230px !important;
        }

        .infobox {
            width: 85% !important;
            bottom: 13vh !important;
            border: 5px solid #ccc !important;
            border-radius: 20px !important;

            .infobox_hearder {
                font-size: 100px !important;
                border-radius: 20px !important;
                font-weight: bold !important;
                padding: 50px 0 60px 0 !important;
            }

            .form_box {
                margin: -40px 60px 30px 60px !important;
                border-left: 20px solid #BA0003 !important;
                padding: 80px !important;

                .top {
                    margin-bottom: 50px !important;

                    .xn_box {
                        padding: 20px 55px !important;
                        border-radius: 20px !important;
                        margin-right: 70px !important;

                        img {
                            width: 120px !important;
                            height: 120px !important;
                        }
                    }

                    .title {
                        font-size: 90px !important;
                    }
                }

                textarea {
                    min-height: 400px !important;
                }

                .centent_box {
                    .input_box {
                        box-sizing: border-box !important;
                        padding: 80px 0 !important;
                        padding-left: 0.7em !important;
                        border: 1px solid #d9dbe4 !important;
                        font-size: 70px !important;
                        border-radius: 20px !important;
                        margin-top: 70px !important;
                    }





                    .Tips {
                        font-size: 65px !important;
                        color: red !important;
                        margin-top: 0 !important;
                    }
                }
            }

            .tj {
                padding: 40px 150px !important;
                background: #BA0003;
                font-size: 90px !important;
                border-radius: 20px !important;
            }
        }
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .tawk-min-chat-icon {
        width: 65px !important;
        height: 65px !important;
    }

    .Information {
        position: fixed;
        bottom: 15vh;
        right: 5vh;
        z-index: 999;
        cursor: pointer;

        .Customer_box {
            padding: 30px !important;
            background: #BA0003;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
        }

        @keyframes fadeIn {
            from {
                transform: scale(0);
                /* 初始状态，缩小为0 */
            }

            to {
                transform: scale(1);
                /* 最终状态，正常大小 */
            }
        }

        .infobox {
            width: 830px !important;
            background: #F7F7F9;
            position: fixed;
            bottom: 25vh;
            right: 2vh;
            border: 1px solid #ccc;
            border-radius: 20px;
            box-sizing: border-box;
            text-align: center;
            animation: fadeIn 0.2s linear;
            padding-bottom: 1.5em;
            z-index: 999999 !important;

            .infobox_hearder {
                background: #BA0003;
                padding: 30px;
                min-height: 2em;
                text-align: center;
                color: #fff;
                border-radius: 10px 10px 0 0;
                font-size: 80px !important;
            }

            .form_box {
                margin: -20px 30px 10px 30px !important;
                background: #fff;
                border-radius: 10px;
                padding: 30px;
                border-left: 6px solid #BA0003;

                .top {
                    display: flex;
                    align-items: center;

                    .xn_box {
                        padding: 10px 15px;
                        background: #BA0003;
                        box-sizing: border-box;
                        margin-right: 15px;
                        border-radius: 10px;

                        img {
                            width: 80px !important;
                            height: 85px !important;
                        }
                    }

                    .title {
                        font-size: 22px;
                        color: #929292;
                    }
                }

                .centent_box {
                    .input_box {
                        box-sizing: border-box !important;
                        color: rgb(0, 0, 0);
                        width: 100%;
                        padding: 20px 0;
                        padding-left: 15px;
                        text-indent: 6px;
                        box-sizing: content-box;
                        border-radius: 10px;
                        border: 1px solid #d9dbe4 !important;
                        margin-top: 25px;
                        font-size: 17px;
                        background: #fff;
                    }

                    .input_box:focus {
                        outline: none;
                    }

                    .Tips {
                        width: 100%;
                        font-size: 16px;
                        color: red !important;
                        margin-top: 10px;
                        text-align: left;
                        padding-left: 6px;
                    }

                    .active {
                        border: 1px solid red;
                    }
                }
            }

            .tj {
                background: #BA0003;
                color: #fff;
                border: 0;
                padding: 15px 50px;
                box-sizing: border-box;
                font-size: 20px;
                margin-top: 18px;
                border-radius: 7px;
                cursor: pointer;
            }
        }

        .tawk-min-chat-icon {
            fill: #fff;
        }
    }
}

.tawk-min-chat-icon {
    width: 33px;
    height: 33px;
}

.cha {
    width: 20px;
    height: 20px;
}

.Information {
    position: fixed;
    bottom: 3vh;
    right: 3vh;
    z-index: 999;
    cursor: pointer;

    .Customer_box {
        width: 0.32rem;
        height: 0.32rem;
        background: #BA0003;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
    }

    @keyframes fadeIn {
        from {
            transform: scale(0);
            /* 初始状态，缩小为0 */
        }

        to {
            transform: scale(1);
            /* 最终状态，正常大小 */
        }
    }

    .infobox {
        width: 350px;
        background: #F7F7F9;
        position: fixed;
        bottom: 11vh;
        right: 2vh;
        border: 1px solid #ccc;
        border-radius: 5px;
        box-sizing: border-box;
        text-align: center;
        animation: fadeIn 0.2s linear;
        padding-bottom: 1.5em;
        z-index: 999999 !important;

        .infobox_hearder {
            background: #BA0003;
            padding: 30px;
            min-height: 2em;
            text-align: center;
            color: #fff;
            border-radius: 5px 5px 0 0;
            font-size: 20px;
        }

        .form_box {
            margin: -20px 15px 10px 15px;
            background: #fff;
            border-radius: 5px;
            padding: 30px;

            .top {
                display: flex;
                align-items: center;

                .xn_box {
                    padding: 4px 10px;
                    background: #BA0003;
                    box-sizing: border-box;
                    margin-right: 15px;
                    border-radius: 5px;

                    img {
                        width: 25px;
                        height: 25px;
                    }
                }

                .title {
                    font-size: 18px;
                    color: #929292;
                }
            }

            .centent_box {
                .input_box {
                    box-sizing: border-box !important;
                    color: rgb(0, 0, 0);
                    width: 100%;
                    padding: 12px 0;
                    padding-left: 15px;
                    text-indent: 6px;
                    box-sizing: content-box;
                    border-radius: 5px;
                    border: 1px solid #d9dbe4 !important;
                    margin-top: 20px;
                    font-size: 13px;
                    background: #fff;
                }

                .input_box:focus {
                    outline: none;
                }

                .Tips {
                    width: 100%;
                    font-size: 14px;
                    color: red !important;
                    margin-top: 10px;
                    text-align: left;
                    padding-left: 6px;
                }

                textarea {
                    max-height: 100px;
                    font-size: 14px !important;
                }

                .active {
                    border: 1px solid red;
                }
            }
        }

        .tj {
            background: #BA0003;
            color: #fff;
            border: 0;
            padding: 10px 40px;
            box-sizing: border-box;
            font-size: 16px;
            margin-top: 18px;
            border-radius: 5px;
            cursor: pointer;
        }
    }

    .tawk-min-chat-icon {
        fill: #fff;
    }
}
</style>